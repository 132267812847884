<template>
	<Error :custom-banner-text="error" />
</template>

<script>
	import Error from '@/components/Error.vue';
	export default {
		components: {
			Error,
		},
		scrollToTop: true,
		data() {
			return {
				error: {
					title: this.$__('Page not found'),
					description: this.$__(
						'The requested page was not found on our server.'
					),
				},
			};
		},
	};
</script>
